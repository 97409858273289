<template>
	<v-row>
		<!-- Номер помещения -->
		<v-col v-if="!disabled && !isMultiple" cols="12" sm="4">
			<span class="subtitle-2 font-weight-light">
				Номер помещения
			</span>
			<v-text-field
				v-model="data.apartment_number"
				class="border-all mt-2"
				solo
				dense
				hide-details="auto"
				flat
				:rules="rules.required"
			></v-text-field>
		</v-col>
		<!-- РКА -->
		<v-col v-if="!disabled && !isMultiple" cols="12" sm="4">
			<span class="subtitle-2 font-weight-light">
				РКА
			</span>
			<v-text-field
				v-model="data.rca"
				class="border-all mt-2"
				v-mask="'################'"
				solo
				dense
				hide-details="auto"
				flat
				:rules="rules.rca"
			></v-text-field>
		</v-col>
		<!-- Номера помещений -->
		<v-col v-if="!disabled && isMultiple" cols="6" md="4">
			<span class="subtitle-2 font-weight-light">
				Помещения от
			</span>
			<v-text-field
				v-model="data.apartment_number_first"
				type="number"
				class="border-all mt-2"
				solo
				dense
				hide-details="auto"
				flat
				:rules="rules.number"
			></v-text-field>
		</v-col>
		<v-col v-if="!disabled && isMultiple" cols="6" md="4">
			<span class="subtitle-2 font-weight-light">
				до
			</span>
			<v-text-field
				v-model="data.apartment_number_second"
				class="border-all mt-2"
				type="number"
				solo
				dense
				hide-details="auto"
				flat
				:rules="rules.number"
			></v-text-field>
		</v-col>
		<!-- Тип помещения -->
		<v-col v-if="!disabled" cols="12" sm="4">
			<span class="subtitle-2 font-weight-light">
				Тип помещения
			</span>
			<v-select
				v-model="data.d_room_type_id"
				class="border-all mt-2"
				solo
				dense
				hide-details="auto"
				flat
				:items="catalog.room_types"
				item-text="name"
				item-value="id"
				:rules="rules.required"
			></v-select>
		</v-col>
		<!-- Кнопки -->
		<v-col v-if="!disabled" cols="12" class="d-flex flex-column pt-0">
			<span class="subtitle-2 font-weight-light">
				Тип добавления
			</span>

			<v-btn-toggle v-model="isMultiple">
				<v-btn :value="false" small class="text-lowercase">
					<span class="text-uppercase">Одну квартиру</span>
					<v-icon right>
						mdi-home
					</v-icon>
				</v-btn>

				<v-btn :value="true" small class="text-lowercase">
					<span class="text-uppercase">Несколько квартир</span>

					<v-icon right>
						mdi-home-group
					</v-icon>
				</v-btn>
			</v-btn-toggle>
		</v-col>

		<!-- Add button -->
		<v-col v-if="!disabled" cols="12" class="pt-0">
			<v-btn
				color="primary"
				depressed
				small
				:loading="isAdding"
				@click="addApartment"
			>
				Добавить
			</v-btn>
		</v-col>

		<!-- Alert -->
		<v-col v-if="alert" cols="12" class="pt-0">
			<v-alert :type="alert.color" dense outlined>{{ alert.message }}</v-alert>
		</v-col>

		<!-- Таблица -->
		<v-col cols="12">
			<VoteTableModalThirdApartments
				ref="apartmentsTable"
				:house-id="houseId"
				:disabled="disabled"
				:fromModal="fromModal"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";

import VoteTableModalThirdApartments from "@/components/VoteTableModalThirdApartments";

export default {
	name: "VoteTableModalThird",
	components: { VoteTableModalThirdApartments },
	props: {
		houseId: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		fromModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: null,
			isMultiple: false,
			isAdding: false,
			data: {
				apartment_number: null,
				apartment_number_first: null,
				apartment_number_second: null,
				d_room_type_id: null,
				rca: null,
			},
			rules: {
				required: [v => !!v || ""],
				number: [v => !!v || "", v => !!v && v >= 0],
				rca: [
					v => v?.length === 0 || v?.length === 16 || "Введите 16 символов",
				],
			},
		};
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 1000);
		},
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
	},
	methods: {
		async addApartment() {
			this.alert = null;
			let type = this.catalog.room_types.find(e => e.id === this.data.d_room_type_id)
			this.data.type = type._id

			if (this.isMultiple) {
				delete this.data.apartment_number;
				delete this.data.rca;
			} else {
				delete this.data.apartment_number_first;
				delete this.data.apartment_number_second;
			}

			const data = {
				house_id: this.houseId,
				...this.data,
			};

			try {
				this.isAdding = true;

				let res;

				if (this.isMultiple)
					res = await this.$api.ksk.vote_add_multiple_apartments(data);
				else res = await this.$api.ksk.vote_store_apartment(data);

				this.$root.snackbar.show({ color: "success", message: res.success || "Успешно добавлено", });
					
				this.$refs.apartmentsTable.loadData();
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.isAdding = false;
			}
		},
	},
	created() {
	},
};
</script>
