<template>
	<v-form
		ref="form2"
		v-model="valid"
		lazy-validation
		@submit.prevent="addQuestion"
	>
		<v-row>
			<!-- Тема голосования -->
			<v-col cols="6">
				<span class="subtitle-2 font-weight-light">
					Тема голосования
				</span>
				<v-text-field
					v-model="questions[qIndex].title"
					class="border-all mt-2"
					solo
					dense
					hide-details
					flat
					required
					:disabled="disabled"
					:rules="rules.required"
				></v-text-field>
			</v-col>
			<!-- Описание голосования -->
			<v-col cols="12">
				<span class="subtitle-2 font-weight-light">
					Описание голосования
				</span>
				<v-textarea
					v-model="questions[qIndex].description"
					class="border-all mt-2"
					min-height="100px"
					solo
					dense
					hide-details
					flat
					required
					:disabled="disabled"
					:rules="rules.required"
				></v-textarea>
			</v-col>
			<v-col v-if="!disabled" cols="12">
				<span class="subtitle-2 font-weight-light">
					Файлы
				</span>
				<v-file-input
					:value="questions[qIndex].files"
					placeholder="Прикрепить"
					class="border-dashed"
					:accept="acceptAllFiles"
					flat
					chips
					multiple
					solo
					dense
					hide-details="auto"
					show-size
					counter
					:rules="rules.files"
					@change="handleFileInputChange"
				></v-file-input>
			</v-col>
			<v-col v-if="!disabled" cols="12">
				<v-btn
					v-show="voteId"
					class="subtitle-2 text-lowercase"
					color="primary"
					depressed
					small
					@click="saveQuestion"
				>
					<span class="text-capitalize">Сохранить</span>
				</v-btn>
			</v-col>
			<!-- Список вопроов -->
			<v-col v-if="questions.length" cols="12" class="d-flex flex-column">
				<span class="subtitle-2 font-weight-light">
					Добавленные вопросы
				</span>

				<v-btn-toggle style="display: block">
					<v-tooltip
						v-for="(q, i) of questions"
						:key="i"
						bottom
						:disabled="disabled"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								class="subtitle-2 text-lowercase"
								small
								@click="moveQuestion(i)"
								@dblclick="deleteQuestion(i, q)"
							>
								<span class="hidden-sm-and-down text-capitalize">
									{{ q.title }}
								</span>
							</v-btn>
						</template>
						<span>
							Нажмите дважды для удаления
							<v-icon small>
								mdi-delete
							</v-icon>
						</span>
					</v-tooltip>
					<v-btn
						v-if="!disabled"
						color="primary"
						class="subtitle-2 text-none"
						small
						@click="addQuestion()"
					> Добавить ещё вопрос
					</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
export default {
	name: "VoteTableModalSecond",

	props: {
		voteId: {
			type: String,
			default: "",
		},
		propQuestions: {
			type: Array,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			qIndex: 0,
			questions: [],
			question: {
				title: "",
				description: "",
				images: [],
				documents: [],
				files: [],
			},
			valid: true,
			alert: null,
			rules: {
				required: [v => !!v || ""],
				files: [
					v =>
						(!!v && v.reduce((acc, cur) => acc + cur.size, 0) < 10485760) ||
						"Размер должен быть менее 10 MB!",
				],
			},
		};
	},

	methods: {
		handleFileInputChange(newFiles, deletedFiles) {
			if(!newFiles.length) {
				console.log('this.questions[this.qIndex]', this.questions[this.qIndex])
				this.questions[this.qIndex].images.forEach(el => {
					this.deleteFiles(this.questions[this.qIndex]._id, el.id)
				});
				this.questions[this.qIndex].documents.forEach(el => {
					this.deleteFiles(this.questions[this.qIndex]._id, el.id)
				});
				this.questions[this.qIndex].files = []
			} else {
				if (newFiles.some((newFile) => this.questions[this.qIndex].files.some((file) => file.name === newFile.name))) {
					console.log('File input change event already handled');
					return;
				}
				console.log('update', newFiles)
				newFiles.forEach(element => {
					this.questions[this.qIndex].files.push(element)
				});
			}
		},
		async deleteFiles(id, fileId) {
			await this.$api.ksk.delete_file_question(id, fileId);
		},
		addQuestion() {
			if (!this.checkValidation()) return;

			this.copyQuestion();
			this.qIndex = this.questions.length - 1;
			this.$refs.form2.reset();
		},

		copyQuestion() {
			const q = this.question.deepCopy();
			this.questions.push(q);
		},

		async deleteQuestion(i, question) {
			if (this.disabled) return;
			if(question?._id) {
				const res = await this.$api.ksk.delete_vote_question(question._id);
				this.$root.snackbar.show({ color: "success", message: res.success });
				this.$emit("load-data");
			}
			
			this.questions.splice(i, 1);
			if (!this.questions.length) this.copyQuestion();
			this.qIndex = this.questions.length - 1;
		},

		checkValidation(move = false) {
			const len = this.questions.length;
			if (
				!move &&
				len > 1 &&
				!this.questions[this.qIndex].title &&
				!this.questions[this.qIndex].description &&
				!this.questions[this.qIndex].files.length
			) {
				this.deleteQuestion(this.qIndex);
				console.log("deleted");
				return true;
			}
			console.log("mal");
			return this.$refs.form2.validate();
		},

		filterFiles(q) {
			for (const f of q.files) {
				if (this.acceptImages.split(", ").includes(f.type)) {
					q.images.push(f);
				} else {
					q.documents.push(f);
				}
			}
		},

		moveQuestion(i) {
			this.alert = null;
			this.qIndex = i;
			if (!this.checkValidation(true)) return;

			
		},

		async saveQuestion() {
			const r = this.checkValidation();
			if (!r) {
				console.log("oops");
				throw { message: "Заполните тему и описание голосования" };
			}
			const q = this.questions[this.qIndex]
			try {
				console.log('Qq', q)
				this.filterFiles(q);
				q.images = q.images.filter(file => file && file.size && file.size > 0);
				q.documents = q.documents.filter(file => file && file.size && file.size > 0);
				q.files = q.files.filter(file => file && file.size && file.size > 0);
				console.log('q!', q)
				const formData = q.toFormData();
				if (q._id) {
					const res = await this.$api.ksk.edit_vote_question(q._id, formData);
					this.$root.snackbar.show({ color: "success", message: res.success });
				} else {
					const res = await this.$api.ksk.add_vote_question(this.voteId, formData);
					this.$root.snackbar.show({ color: "success", message: res.success });
				}
				this.$emit("load-data");
				return { success: "Изменения сохранены" };
			} catch (error) {
				console.log("ER", error)
				this.$root.snackbar.show({ color: "red", message: error.message });
				throw error;
			}
		},
		async saveQuestions() {
			const r = this.checkValidation();
			console.log('r', r);
			console.log('this.questions', this.questions);
			if (!r) {
				console.log("oops");
				throw { message: "Заполните тему и описание голосования" };
			}

			try {
				for (const q of this.questions) {
					console.log('Qq', q)
					this.filterFiles(q);
					const formData = q.toFormData();
					if (q._id) {
						await this.$api.ksk.edit_vote_question(q._id, formData);
					} else {
						await this.$api.ksk.add_vote_question(this.voteId, formData);
					}
				}

				return { success: "Изменения сохранены" };
			} catch (error) {
				throw error;
			}
		},

		async postQuestions(vote_id) {
			if (!this.checkValidation())
				throw { message: "Заполните все выделенные поля" };

			try {
				for (const q of this.questions) {
					this.filterFiles(q);
					const formData = q.toFormData();
					await this.$api.ksk.add_vote_question(vote_id, formData);
				}
			} catch (error) {
				this.$root.snackbar.show({ color: "red", message: error.message });
				throw error;
			}
		},
	},

	created() {
		if (this.voteId) {
		this.questions = this.propQuestions.map(question => {
			let files = [];

			// Обрабатываем как изображения, так и документы
			['images', 'documents'].forEach(type => {
				if (question[type] && question[type].length > 0) {
					files = [
						...files,
						...question[type].map(file => {
							return new File([], file.original_name, { type: `image/${file.extension}` });
						})
					];
				}
			});

			return {
				...question,
				files: files
			};
		});




		console.log('this.questions', this.questions)
		if (!this.questions.length) {
			this.copyQuestion();
			this.questions.forEach(e => (e.files = []));
		}
		} else {
			this.copyQuestion();
			this.questions.forEach(e => (e.files = []));
		}
	},
};
</script>
