var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vote-apartment-table"},[(_vm.alert)?_c('span',{class:("subtitle-2 " + (_vm.alert.color) + "--text")},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]):_vm._e(),_c('v-data-table',{staticClass:"overflow-x elevation-0",attrs:{"height":"100%","item-key":"name","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.options.page,"options":_vm.options,"server-items-length":_vm.options.itemsLength},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{staticClass:"justify-end pa-3 border-bottom",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"","items-per-page-options":[],"disable-items-per-page":"","disable-pagination":_vm.loading,"show-first-last-page":true},on:{"update:options":updateOptions}})]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{staticClass:"border-all",attrs:{"solo":"","dense":"","flat":"","hide-details":""},on:{"change":_vm.updateQuery},model:{value:(_vm.filter.apartment_number),callback:function ($$v) {_vm.$set(_vm.filter, "apartment_number", $$v)},expression:"filter.apartment_number"}})],1),_c('td',[_c('v-select',{staticClass:"border-all",attrs:{"solo":"","dense":"","hide-details":"auto","flat":"","items":[{ name: 'Все', id: null } ].concat( _vm.catalog.room_types),"item-text":"name","item-value":"id"},on:{"change":_vm.updateQuery},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1),_c('td',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('################'),expression:"'################'"}],staticClass:"border-all",attrs:{"solo":"","dense":"","flat":"","hide-details":""},on:{"change":_vm.updateQuery},model:{value:(_vm.filter.rca),callback:function ($$v) {_vm.$set(_vm.filter, "rca", $$v)},expression:"filter.rca"}})],1),_c('td')])]},proxy:true},{key:"item.rca",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('################'),expression:"'################'"}],staticClass:"border-all",attrs:{"solo":"","dense":"","flat":"","hide-details":"","disabled":_vm.disabled,"error":String(item.rca).length > 0 && String(item.rca).length < 16},on:{"change":function($event){return _vm.setApartmentIIN(item)}},model:{value:(item.rca),callback:function ($$v) {_vm.$set(item, "rca", $$v)},expression:"item.rca"}})],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("getCatalogValue")(item.type,"room_types", "_id"))+" ")])]}},{key:"item.delete_apartment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{staticClass:"subtitle-2 text-capitalize",attrs:{"color":"red white--text","small":"","depressed":"","disabled":_vm.disabled,"loading":_vm.isDeleting === item._id},on:{"click":function($event){return _vm.deleteApartment(item)}}},[_vm._v(" Удалить ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }