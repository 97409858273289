<template>
	<div class="vote-apartment-table">
		<span v-if="alert" :class="`subtitle-2 ${alert.color}--text`">
			{{ alert.message }}
		</span>
		<v-data-table
			class="overflow-x elevation-0"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Номер помещения -->
					<td>
						<v-text-field
							v-model="filter.apartment_number"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Тип помещения -->
					<td>
						<v-select
							v-model="filter.type"
							class="border-all"
							solo
							dense
							hide-details="auto"
							flat
							:items="[{ name: 'Все', id: null }, ...catalog.room_types]"
							item-text="name"
							item-value="id"
							@change="updateQuery"
						></v-select>
					</td>
					<!-- RCA -->
					<td>
						<v-text-field
							v-model="filter.rca"
							v-mask="'################'"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td></td>
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.rca`]="{ item }">
				<td>
					<v-text-field
						v-model="item.rca"
						v-mask="'################'"
						class="border-all"
						solo
						dense
						flat
						hide-details
						:disabled="disabled"
						:error="String(item.rca).length > 0 && String(item.rca).length < 16"
						@change="setApartmentIIN(item)"
					>
					</v-text-field>
				</td>
			</template>
			<template v-slot:[`item.type`]="{ item }">
				<td>
					{{ item.type | getCatalogValue("room_types", "_id") }} 
					<!-- {{item.type.name}} -->
				</td>
			</template>
			<template v-slot:[`item.delete_apartment`]="{ item }">
				<td>
					<v-btn
						color="red white--text"
						class="subtitle-2 text-capitalize"
						small
						depressed
						:disabled="disabled"
						:loading="isDeleting === item._id"
						@click="deleteApartment(item)"
					>
						Удалить
					</v-btn>
				</td>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "VoteTableModalThirdApartments",
	props: {
		houseId: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		fromModal: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		options: {
			handler() {
				const query = { ...this.$route.query, page: this.options.page };
				// this.$router.replace({ query })
				this.loadData(query);
			},
			deep: true,
		},
		// alert(val) {
		// 	if (!val) return;
		// 	setTimeout(() => {
		// 		this.alert = null;
		// 	}, 1000);
		// },
	},
	data() {
		return {
			items: [],
			rooms: [],
			filter: {
				apartment_number: this.$route.query.apartment_number || "",
				type: this.$route.query.type || "",
				rca: this.$route.query.rca || "",
			},
			filtersQuery: {
				"apartment_number": "apartment_number",
				"type": "type",
				"rca": "rca",
			},
			query: null,
			headers: [
				{
					text: "Квартира",
					value: "apartment_number",
					width: "120px",
					sortable: false,
				},
				{
					text: "Тип помещения",
					value: "type",
					sortable: false,
				},
				{
					text: "РКА",
					value: "rca",
					sortable: false,
				},
				{
					text: "Удаление квартиры",
					value: "delete_apartment",
					sortable: false,
				},
			],
			options: {
				page: 1,
				itemsPerPage: 10,
				pageStart: 0,
				pageCount: 0,
				itemsLength: 0,
			},
			alert: null,
			isDeleting: -1,
			loading: true,
			timer: null,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
	},
	methods: {
		async setApartmentIIN(item) {
			this.alert = {
				autosave: 1,
				color: "grey",
				message: "Автосохранение..",
			};
			clearTimeout(this.timer);
			this.timer = await setTimeout(async () => {
				console.log("saving iin", String(item.rca).length);
				try {
					if (String(item.rca).length !== 16) {
						throw { message: "Не удалось сохранить изменения" };
					}
					const res = await this.$api.ksk.set_apartment_iin(item._id, {
						rca: item.rca,
					});
					console.log(res);
					this.alert = {
						autosave: 2,
						color: "success",
						message: "Изменения сохранены",
					};
				} catch (error) {
					this.alert = {
						autosave: 3,
						color: "error",
						message: error.message || "Ошибка",
					};
				}
			}, 1000);
		},

		async deleteApartment(item) {
			this.alert = null;

			try {
				this.isDeleting = item._id;
				await this.$api.ksk.delete_apartment(item._id);
				this.alert = {
					color: "success",
					message: "Квартира удалена",
				};
				const query = { page: this.options.page };
				this.loadData(query);
			} catch (error) {
				this.alert = {
					color: "red",
					message: "Не удалось удалить квартиру",
				};
				console.error(error);
			} finally {
				this.isDeleting = -1;
			}
		},
		async loadRooms() {
			this.loading = true;
			try {
				const res = await this.$api.house.get_house_apartments(this.houseId);
				if(res) {
					this.rooms = res.filter(e => e.rca !== null);
					console.log('rooms length', this.rooms.length)
					this.addApartmentsToVote()
				}
			} catch (error) {
				console.log('error', error)
			}
		},
		async addApartmentsToVote() {
			this.loading = true;
			for(let i = 0; i < this.rooms.length; i++) {
				try {
					let type = this.catalog.room_types.find(e => e.id === this.rooms[i].type.id)
					let data = {
						rca: this.rooms[i].rca,
						apartment_number: this.rooms[i].apartment_number,
						d_room_type_id: type.id,
						type: this.rooms[i].type.id,
						house_id: this.rooms[i].house.id,
					}
					console.log('data', data)
					await this.$api.ksk.vote_store_apartment(data);
				} catch (error) {
					console.log('err', error)
				}
			}
			console.log('finish for')
			this.loadData()
		},
		async loadData(params) {
			this.loading = true;
			return this.$api.ksk
				.load_vote_apartments(this.houseId, params)
				.then(res => {
					this.options.page = res.page;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.query = query;
			this.query.page = this.options.page;
			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	mounted() {
		try {
			const query = { ...this.$route.query };
			if(this.fromModal) {
				this.loadRooms(query);
			} else {
				this.$router.replace({ query: { ...query, page: this.options.page } });
				this.loadData(query);
			}
		} catch (error) {
			console.error(error);
		}
	},
};
</script>
