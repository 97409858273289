import { render, staticRenderFns } from "./VoteTable.vue?vue&type=template&id=2857a3ea&scoped=true&"
import script from "./VoteTable.vue?vue&type=script&lang=js&"
export * from "./VoteTable.vue?vue&type=script&lang=js&"
import style0 from "./VoteTable.vue?vue&type=style&index=0&id=2857a3ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2857a3ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VDataFooter,VDataTable,VIcon,VTooltip})
